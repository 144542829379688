import { getWindowLocationOrigin } from '../../utils/window'
import { generateMetaTags } from '../../utils/helmet'

const title = 'Supported Headsets | IrisVR'
const description =
  'IrisVR software works with most VR and MR headsets, including Oculus Rift, Oculus Rift S, Meta Quest 2, HTC Vive, Vive Pro, Windows MR, Pimax 5K Plus, HP Reverb, and more.'
const url = `${getWindowLocationOrigin()}/supported-headsets`
const image =
  'https://s3.amazonaws.com/iris-opengraph-images/ImgTag_Quest.jpg.png'
const imageWidth = 1200
const imageHeight = 630

export default generateMetaTags({
  title,
  description,
  image,
  url,
  imageWidth,
  imageHeight,
})
