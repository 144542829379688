import React, { memo } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from '../components/Shared'
import HeadsetOverview from '../components/Headsets'
import helmetProps from '../components/Headsets/helmetProps'

export const query = graphql`
  {
    riftLogo: file(
      relativeDirectory: { eq: "headsets" }
      name: { eq: "1.Rift_Logo" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 640, maxHeight: 200, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    rift: file(relativeDirectory: { eq: "headsets" }, name: { eq: "1.Rift" }) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 640, maxHeight: 400, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    riftSLogo: file(
      relativeDirectory: { eq: "headsets" }
      name: { eq: "2.RiftS.Logo" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 640, maxHeight: 200, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    riftS: file(
      relativeDirectory: { eq: "headsets" }
      name: { eq: "2.RiftS" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 640, maxHeight: 400, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    quest: file(
      relativeDirectory: { eq: "headsets" }
      name: { eq: "meta-quest-headset" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 640, maxHeight: 400, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    questLogo: file(
      relativeDirectory: { eq: "headsets" }
      name: { eq: "meta-quest-logo" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 640, maxHeight: 200, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    vive: file(relativeDirectory: { eq: "headsets" }, name: { eq: "4.Vive" }) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 640, maxHeight: 400, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    viveLogo: file(
      relativeDirectory: { eq: "headsets" }
      name: { eq: "4.ViveLogo" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 640, maxHeight: 200, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    vivePro: file(
      relativeDirectory: { eq: "headsets" }
      name: { eq: "5.VivePro" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 640, maxHeight: 400, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    viveProLogo: file(
      relativeDirectory: { eq: "headsets" }
      name: { eq: "5.ViveProLogo" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 640, maxHeight: 200, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    valve: file(
      relativeDirectory: { eq: "headsets" }
      name: { eq: "6.Valve" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 640, maxHeight: 400, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    valveLogo: file(
      relativeDirectory: { eq: "headsets" }
      name: { eq: "6.ValveLogo" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 640, maxHeight: 200, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    pi: file(relativeDirectory: { eq: "headsets" }, name: { eq: "7.Pi" }) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 640, maxHeight: 400, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    piLogo: file(
      relativeDirectory: { eq: "headsets" }
      name: { eq: "7.PiLogo" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 640, maxHeight: 200, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    hp: file(relativeDirectory: { eq: "headsets" }, name: { eq: "8.HP" }) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 640, maxHeight: 400, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    windowsLogo: file(
      relativeDirectory: { eq: "headsets" }
      name: { eq: "8.WindowsLogo" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 640, maxHeight: 200, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    dell: file(relativeDirectory: { eq: "headsets" }, name: { eq: "9.Dell" }) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 610, maxHeight: 384, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    samsung: file(
      relativeDirectory: { eq: "headsets" }
      name: { eq: "10.Samsung" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 640, maxHeight: 400, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    acer: file(relativeDirectory: { eq: "headsets" }, name: { eq: "11.Acer" }) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 610, maxHeight: 384, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    lenovo: file(
      relativeDirectory: { eq: "headsets" }
      name: { eq: "12.Lenovo" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 640, maxHeight: 400, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    hpMixed: file(
      relativeDirectory: { eq: "headsets" }
      name: { eq: "13.HpMixed" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 640, maxHeight: 400, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    startTrialProspectLogo: file(
      relativeDirectory: { eq: "trial" }
      name: { eq: "start-trial-prospect" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 225) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

export default memo(props => {
  const data = props.data
  const prospectLogo = data.startTrialProspectLogo

  const headsetData = {
    rift: {
      logo: data.riftLogo,
      headset: data.rift,
      name: 'Oculus Rift',
    },
    riftS: {
      logo: data.riftSLogo,
      headset: data.riftS,
      name: 'Oculus Rift S',
    },
    quest: {
      logo: data.questLogo,
      headset: data.quest,
      name: 'Meta Quest 2',
    },
    vive: {
      logo: data.viveLogo,
      headset: data.vive,
      name: 'HTC Vive',
    },
    vivePro: {
      logo: data.viveProLogo,
      headset: data.vivePro,
      name: 'HTC Vive Pro',
    },
    valve: {
      logo: data.valveLogo,
      headset: data.valve,
      name: 'Valve Index',
    },
    pi: {
      logo: data.piLogo,
      headset: data.pi,
      name: 'Pimax 5K Plus, 5K XR, 8K',
    },
    hp: {
      logo: data.windowsLogo,
      headset: data.hp,
      name: 'HP Reverb',
    },
    dell: {
      logo: data.windowsLogo,
      headset: data.dell,
      name: 'Dell Visor',
    },
    windowsSamsung: {
      logo: data.windowsLogo,
      headset: data.samsung,
      name: 'Samsung HMD Odyssey / Odyssey+',
    },
    windowsAcer: {
      logo: data.windowsLogo,
      headset: data.acer,
      name: 'Acer Windows Mixed Reality Headset',
    },
    windowsLenovo: {
      logo: data.windowsLogo,
      headset: data.lenovo,
      name: 'Lenovo Explorer',
    },
    windows: {
      logo: data.windowsLogo,
      headset: data.hpMixed,
      name: 'HP Windows Mixed Reality Headset',
    },
  }

  return (
    <>
      <Helmet {...helmetProps} />
      <HeadsetOverview headsetData={headsetData} prospectLogo={prospectLogo} />
    </>
  )
})
